.loader-container{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.trans-loader-container{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.253);
    z-index: 1000000;
}
